export enum Rating {
    NR = 'NR',
    AAA = 'AAA',
    AAAX = 'AAAX',
    AAAJR = 'AAAJr',
    AA = 'AA',
    A = 'A',
    BBB = 'BBB',
    BBBJR = 'BBBJr',
    BB = 'BB',
    B = 'B',
    CCC = 'CCC',
    D = 'D'
}

export const Ratings: Rating[] = [
    Rating.AAA,
    Rating.AA,
    Rating.A,
    Rating.BBB,
    Rating.BB,
    Rating.B,
    Rating.CCC,
    Rating.D,
    Rating.NR
];

export const IssuanceMonitorRatings: {value: Rating, text?: string}[] = [
    {value: Rating.AAAX, text: 'AAA-X'},
    {value: Rating.AAA},
    {value: Rating.AAAJR, text: 'AAA-Jr'},
    {value: Rating.AA},
    {value: Rating.A},
    {value: Rating.BBB},
    {value: Rating.BBBJR, text: 'BBB-Jr'},
    {value: Rating.BB},
    {value: Rating.B},
    {value: Rating.NR}
];

export const ratingSortOrder = {
    [Rating.AAAX]: 0,
    [Rating.AAA]: 1,
    [Rating.AAAJR]: 2,
    [Rating.AA]: 3,
    [Rating.A]: 4,
    [Rating.BBB]: 5,
    [Rating.BBBJR]: 6,
    [Rating.BB]: 7,
    [Rating.B]: 8,
    [Rating.CCC]: 9,
    [Rating.D]: 10,
    [Rating.NR]: 11,
    null: 12
};

export const ClassRatingsM = [
    'Aaa', 'Aa1', 'Aa2', 'Aa3', 'A1',
    'A2', 'A3', 'Baa1', 'Baa2', 'Baa3',
    'Ba1', 'Ba2', 'Ba3', 'B1', 'B2',
    'B3', 'Caa1', 'Caa2', 'Caa3', 'Ca',
    'C', 'NR'
];

export const ClassRatingsS = [
    'AAA', 'AA+', 'AA', 'AA-', 'A+',
    'A', 'A-', 'BBB+', 'BBB', 'BBB-',
    'BB+', 'BB', 'BB-', 'B+', 'B',
    'B-', 'CCC+', 'CCC', 'CCC-', 'CC',
    'C', 'D', 'NR'
];

export const ClassRatingsF = [
    'AAA', 'AA+', 'AA', 'AA-', 'A+',
    'A', 'A-', 'BBB+', 'BBB', 'BBB-',
    'BB+', 'BB', 'BB-', 'B+', 'B',
    'B-', 'CCC', 'DDD', 'DD', 'D', 'NR'
];

export const ClassRatingsK = [
    'AAA', 'AA+', 'AA', 'AA-', 'A+',
    'A', 'A-', 'BBB+', 'BBB', 'BBB-',
    'BB+', 'BB', 'BB-', 'B+', 'B',
    'B-', 'CCC+', 'CCC', 'CCC-', 'CC',
    'C', 'D', 'NR',
];

export const ClassRatingsD = [
    'AAA', 'AA (high)', 'AA', 'AA (low)', 'A (high)',
    'A', 'A (low)', 'BBB (high)', 'BBB', 'BBB (low)',
    'BB (high)', 'BB', 'BB(low)', 'B (high)', 'B',
    'B (low)', 'CCC (high)', 'CCC', 'CCC (low)', 'CC (high)',
    'CC', 'CC (low)', 'C (high)', 'C', 'C (low)', 'D', 'NR',
];
