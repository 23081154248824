export enum TrancheFloaterIndex {
    Fixed = 'Fixed',
    SOFR = 'SOFR',
    LIBOR1M = 'LIBOR 1M',
    LIBOR3M = 'LIBOR 3M',
    EURIBOR3M = 'EURIBOR 3M',
    EURIBOR6M = 'EURIBOR 6M'
}

export const trancheFloaterIndexList = [
    { key: TrancheFloaterIndex.Fixed, title: TrancheFloaterIndex.Fixed },
    { key: TrancheFloaterIndex.SOFR, title: TrancheFloaterIndex.SOFR },
    { key: TrancheFloaterIndex.LIBOR1M, title: TrancheFloaterIndex.LIBOR1M },
    { key: TrancheFloaterIndex.LIBOR3M, title: TrancheFloaterIndex.LIBOR3M },
    { key: TrancheFloaterIndex.EURIBOR3M, title: TrancheFloaterIndex.EURIBOR3M },
    { key: TrancheFloaterIndex.EURIBOR6M, title: TrancheFloaterIndex.EURIBOR6M },
];
