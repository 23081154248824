export interface ManageEmailDetails {
    bcc: string | null;
    cc: string | null;
    created: Date;
    emailEvents: ManageEmailDetailsEvent[];
    emailType: number;
    subject: string;
    from: string;
    sender: string;
    to: string;
}

export interface ManageEmailDetailsEvent {
    id: number;
    email: string;
    emailEventType: ManageEmailDetailsEventStatus;
    reason: string | null
    timestamp: Date;
}

export enum ManageEmailDetailsEventStatus {
    Processed = 1,
    Dropped = 2,
    Deferred = 3,
    Delivered = 4,
    Open = 5,
    Click = 6,
    Bounce = 7,
    SpamReport = 8,
    Unsubscribe = 9
}

export const ManageEmailDetailsEventStatusLabels = {
    [ManageEmailDetailsEventStatus.Processed]: 'Processed',
    [ManageEmailDetailsEventStatus.Dropped]: 'Dropped',
    [ManageEmailDetailsEventStatus.Deferred]: 'Deferred',
    [ManageEmailDetailsEventStatus.Delivered]: 'Delivered',
    [ManageEmailDetailsEventStatus.Open]: 'Open',
    [ManageEmailDetailsEventStatus.Click]: 'Click',
    [ManageEmailDetailsEventStatus.Bounce]: 'Bounce',
    [ManageEmailDetailsEventStatus.SpamReport]: 'Spam Report',
    [ManageEmailDetailsEventStatus.Unsubscribe]: 'Unsubscribe',
}

export interface ManageEmailTypeOption {
    value: number;
    description: string;
}
