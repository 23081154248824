export enum DealUpdateIdentifier {
    PreMarketingAAA = 'PreMarketingAAA',
    GuidanceUpdated = 'GuidanceUpdated',
    SubscriptionUpdated = 'SubscriptionUpdated',
    StructureUpdated = 'StructureUpdated',
    MaterialsUpdated = 'MaterialsUpdated',
    Launch = 'Launch',
    Upsized = 'Upsized',
    AllTranchesSubject = 'AllTranchesSubject',
    FinalPricingDetails = 'FinalPricingDetails',
    Priced = 'Priced',
    TransactionCreated = 'TransactionCreated',
    OverviewUpdated = 'OverviewUpdated',
    CollateralAssumptionsUpdated = 'CollateralAssumptionsUpdated',
    SourcesAndUsesUpdated = 'SourcesAndUsesUpdated',
    CollateralQualityTestsUpdated = 'CollateralQualityTestsUpdated',
    ManagementFeesUpdated = 'ManagementFeesUpdated',
    TargetPortfolioUpdated = 'TargetPortfolioUpdated',
    DocumentsUpdated = 'DocumentsUpdated',
    Closed = 'Closed',
    TickerAdded = 'TickerAdded',
    CUSIPsAdded = 'CUSIPsAdded',
    DatesUpdated = 'DatesUpdated',
    IntexUpdated = 'IntexUpdated',
    RollerDeadlineUpdated = 'RollerDeadlineUpdated',
    StatusUpdated = 'StatusUpdated',
}

export const dealUpdatesIdentifiersTitles = {
    [DealUpdateIdentifier.PreMarketingAAA]: 'Pre-Marketing AAA',
    [DealUpdateIdentifier.GuidanceUpdated]: 'Guidance Updated',
    [DealUpdateIdentifier.SubscriptionUpdated]: 'Subscription Updated',
    [DealUpdateIdentifier.StructureUpdated]: 'Structure Updated',
    [DealUpdateIdentifier.MaterialsUpdated]: 'Materials Updated',
    [DealUpdateIdentifier.Upsized]: 'Upsized',
    [DealUpdateIdentifier.AllTranchesSubject]: 'All Tranches Subject',
    [DealUpdateIdentifier.FinalPricingDetails]: 'Final Pricing Details',
    [DealUpdateIdentifier.Priced]: 'Priced',
    [DealUpdateIdentifier.Launch]: 'Launch',
    [DealUpdateIdentifier.TransactionCreated]: 'Transaction Created',
    [DealUpdateIdentifier.OverviewUpdated]: 'Overview Updated',
    [DealUpdateIdentifier.CollateralAssumptionsUpdated]: 'Collateral Assumptions Updated',
    [DealUpdateIdentifier.SourcesAndUsesUpdated]: 'Sources and Uses Updated',
    [DealUpdateIdentifier.CollateralQualityTestsUpdated]: 'Portfolio Summary Updated',
    [DealUpdateIdentifier.ManagementFeesUpdated]: 'Management Fees Updated',
    [DealUpdateIdentifier.TargetPortfolioUpdated]: 'Target Portfolio Updated',
    [DealUpdateIdentifier.DocumentsUpdated]: 'Documents Updated',
    [DealUpdateIdentifier.Closed]: 'Closed',
    [DealUpdateIdentifier.TickerAdded]: 'Ticker Added',
    [DealUpdateIdentifier.CUSIPsAdded]: 'Identifiers 1 Added',
    [DealUpdateIdentifier.DatesUpdated]: 'Dates Updated',
    [DealUpdateIdentifier.IntexUpdated]: 'Intex Updated',
    [DealUpdateIdentifier.RollerDeadlineUpdated]: 'Roller Deadline Updated',
    [DealUpdateIdentifier.StatusUpdated]: 'Status Updated',
};
